import { GetServerSideProps, NextApiRequest, NextApiResponse } from 'next'

import CategorySection from '../components/CategorySection'
import Container from '../components/Container'
import Layout from '../components/Layout'
import ArticleFilters from '../components/ArticleFilters'
import MainNew from '../components/MainNew'
import NewResume from '../components/NewResume'
import SideSection from '../components/SideSection'
import { useUser } from '../context/Auth'
import { SSRPageArticles } from '../types'
import { getArticlesSSR } from '../utils/articles'
import { getSettingsSSR } from '../utils/settings'
import { getServerSideProps as getSSRProps } from '../utils/settings'
import GuestHomePage from '../components/GuestHomePage' // Import the custom page

const HomePage: React.FC<SSRPageArticles & { notLoggedIn: boolean }> = (props) => {
  const { sections, latest, articles, popular, notLoggedIn } = props

  // If user is not logged in, render the NotLoggedInPage component
  if (notLoggedIn) {
    return <GuestHomePage />
  }

  const user = useUser({ redirectTo: '/login' })
  const mainNew = articles[0]
  const news = [...articles].slice(1)

  return (
    <Layout>
      <Container className="pt-6 pb-24">
        <ArticleFilters />
        {articles.length > 0 && <MainNew {...mainNew} className="mb-12" />}
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-12">
          {articles.length > 0 &&
            news.map((n, index) => (
              <NewResume key={`new-resume-${n.id}-${index}`} {...n} />
            ))}
        </div>
        <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-[65%_1fr] md:gap-12 lg:mt-20">
          <div className="flex w-full flex-col gap-12">
            {sections.map((section) => (
              <CategorySection
                name={section.name}
                slug={section.slug}
                key={section.slug}
                news={section.articles.slice(0, 4)}
              />
            ))}
          </div>

          <div>
            <div className="md:sticky md:top-[120px] lg:top-[150px]">
              <div className="flex flex-col gap-y-12">
                {latest.length > 0 && (
                  <SideSection name="latest news" news={latest} />
                )}
                {popular.length > 0 && (
                  <SideSection name="most popular" news={popular} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps<any> = async function ({
  req,
  res,
}) {

  // Set cache control headers to disable caching
  res.setHeader('Cache-Control', 'no-store, no-cache, must-revalidate, proxy-revalidate')
  res.setHeader('Pragma', 'no-cache')
  res.setHeader('Expires', '0')
  res.setHeader('Surrogate-Control', 'no-store')

  const data = await getArticlesSSR(
    req as NextApiRequest,
    res as NextApiResponse
  )

  // If there's no session, set a flag to show the NotLoggedInPage
  if (!data) {
    const pageProps = await getSSRProps(
      req as NextApiRequest,
      res as NextApiResponse
    )

    return {
      props: {
        settings: pageProps?.props?.settings,
        notLoggedIn: true, // Add this flag to the props
      },
    }
  }

  if (!data.permitted && !data.data) {
    return {
      redirect: {
        permanent: false,
        destination: '/no-license',
      },
      props: {},
    }
  }

  const settings = await getSettingsSSR(
    req as NextApiRequest,
    res as NextApiResponse
  )

  return {
    props: { ...data.data, settings, notLoggedIn: false }, // Explicitly set `notLoggedIn: false`
  }
}

export default HomePage
